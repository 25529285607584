<template>
  <v-layout>
    <v-row class="quote d-flex justify-center" dense v-if="item && item.id">
      <v-col cols="12" md="8">
        <v-card>
          <div class="">
            <div>
              <v-card-actions class="caption">
                <v-btn small @click.stop="$router.go(-1)" icon>
                  <v-icon left>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                {{ item.view_count }} {{ $lang("Views") }}
              </v-card-actions>
              <v-divider></v-divider>

              <v-card-text class="my-2">
                <latex-html
                  hasClass="display-1"
                  :html="
                    $store.getters.getCurrLocale == 'hi'
                      ? item.quote_hi
                        ? item.quote_hi
                        : item.quote
                      : item.quote
                  "
                />
                <v-img
                  v-if="item.quote_image"
                  contain
                  class="my-4"
                  :src="item.quote_image"
                ></v-img>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-title class="headlines">
                <v-avatar v-if="item.author_image" size="60" tile>
                  <v-img :src="item.author_image"></v-img>
                </v-avatar>
                <v-spacer></v-spacer>
                {{
                  $store.getters.getCurrLocale == "hi"
                    ? item.author_hi
                      ? item.author_hi
                      : item.author
                    : item.author
                }}
              </v-card-title>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="mt-4">
        <v-card>
          <v-btn
            color="primary"
            outlined
            large
            block
            @click="$router.push({ name: 'quotes' })"
          >
            {{ isHindi ? "और विचार देखें" : "View more quotes" }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";
import LatexHtml from "@/components/LatexHtml";
import bus from "@/bus";

export default {
  name: "quote",
  components: {
    LatexHtml,
  },
  data() {
    return {
      item: null,
    };
  },
  computed: {
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  methods: {
    fetchItem() {
      bus.$emit("showWait");
      return axios
        .get("quotes/public/" + this.$route.params.id)
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("setAlert", {
            message: this.$lang("notfound"),
          });
          this.$router.replace({ name: "quotes" });
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItem();
  },
};
</script>
<style scoped>
.fit-image {
  max-width: 85vw;
  margin: 0 auto;
}
</style>

